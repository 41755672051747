//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IndentedContent from '../Partials/IndentedContent.vue';
export default {
    components: {IndentedContent},
    name: 'SmallFacts',
    props: ['data'],
};
